/* eslint-disable react-hooks/exhaustive-deps */
import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { isMobile } from 'react-device-detect';
import { Beforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import { useInject } from 'inversify-hooks';
import Cookie from 'js-cookie';
import { getTimezone } from 'casino-fabric-l10n/lib/localization-helper';
import { isGameWindowDisabled } from '../../common/wrapper-bridge-mobile';

import config from '../../../config/config';

//Components
import Loader from '../../components/Loader/Loader';
import GameWindow from '../../components/GameWindow/GameWindow';
import Maintenance from '../../components/GameWindow/components/Maintenance/Maintenance';

//Hooks
import { useAuthenticated } from '../../common/hooks';
import useLoading from '../../common/hooks/useLoading';
import useMessageHandler from '../../common/hooks/use-message-handler';
import { useUserTrackingSetup } from '../../common/hooks/use-user-tracking/useUserTrackingSetup';
import useRemoveInitialLoader from '../../common/hooks/use-remove-initial-loader/use-remove-initial-loader';
import { useSessionTime } from '../../common/hooks/useSessionTime';
import { useCurrentTime } from '../../common/hooks/useCurrentTime';
import { useGameTime } from '../../common/hooks/useGameTime';
import { useExit } from '../../common/hooks/use-exit/use-exit';

//Helpers
import { getAWCookies, getRegion, getCookieValue, removeWrapperAppConfig } from '../../common/helpers/cookies';
import { MODULES, ZONES, getAmplitudeKey } from '../../common/hooks/use-user-tracking/types';
import { getQueryParameter } from '../../common/helpers/queryparams';

//Actions
import { fetchGameWindow } from '../../redux/actions/game-window/fetch-game-window';
import { getUserContext } from '../../redux/actions/user-context/user-context';

//Constants
import { APPTENTIVE_EVENTS } from '../../common/constants/game-constants';
import { DI_SERVICE } from '../../dependency-injection/constants';
import { AW_COOKIE, TIMERS_TIMESTAMPS, COOKIES, GAME_CONTEXT, RGIS } from '../../common/constants';
import { useQueryParameterValidation } from '../../common/hooks/use-query-parameter-validation';
import { setGameContext } from '../../redux/actions/gameContext';
import { useLogGameLaunched } from '../../common/hooks/useLogGameLaunched';
import { overallStatusSelector, providerSelector } from '../../selectors/gameContext';
import { useLogTracking } from '../../common/hooks/use-user-tracking/useUserTracking';
import { setGameClientHandshake } from '../../redux/actions/gameClientStatus';
import { swjEnabledSelector, swjFeatureFlagSelector } from '../../selectors/site-wide-jackpot';
//Styles
require(`./styles/${process.env.APPLICATION}/style.scss`);

const { authToken } = getAWCookies();
export const pathName = '/';

const { SERVER_MARK } = TIMERS_TIMESTAMPS;

const Home = props => {
    const dispatch = useDispatch(); const gameUid = props?.gameUid;
    const userId = useSelector(state => state?.session?.userId);
    const userSessionStartTime = useSelector(state => state?.session?.sessionStartTime);
    const timezone =
        useSelector(state => state?.session?.timezone) ||
        getTimezone(config?.countryCode, getQueryParameter('region') || Cookie.get(AW_COOKIE.REGION));

    const { lastRequest } = useLoading();
    const gameProvider = useSelector(providerSelector);
    const overallStatus = useSelector(overallStatusSelector);
    const swjToggle = useSelector(swjEnabledSelector);
    const isPartOfSWJExperiment = useSelector(state => state?.amplitudeExperiments?.experiments?.swjEnabled) === 'site-wide-jackpots';
    const swjFeatureFlag = useSelector(swjFeatureFlagSelector);
    const [getCurrentSessionDetails] = useInject(DI_SERVICE.getCurrentSessionDetails);
    const [getUserDetails] = useInject(DI_SERVICE.getUserDetails);
    const [getServerTime] = useInject(DI_SERVICE.getServerTime);
    const [WindowTitle] = useInject(DI_SERVICE.windowTitle);
    const [sendToApptentive] = useInject(DI_SERVICE.sendToApptentive);
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);
    const [useGeocomplyFlow] = useInject(DI_SERVICE.geocomplyFlow);

    const useValidation = useQueryParameterValidation(gameUid);
    const rgi = (getQueryParameter('RGI') ?? '').toLowerCase();

    const [tickerInitialized, setTickerInitialized] = useState(false);
    const [userTrackingInitialized, setUserTrackingInitialized] = useState(false);
    const exitGame = useExit();
    const { logTrackingComponent } = useLogTracking();

    useLogGameLaunched();
    useAuthenticated();
    useMessageHandler(dispatch);
    useUserTrackingSetup(userTrackingInitialized, () => setUserTrackingInitialized(true));
    useGeocomplyFlow();
    useRemoveInitialLoader();
    useCurrentTime(tickerInitialized);
    useSessionTime(tickerInitialized);
    useGameTime(tickerInitialized);

    const isValid = useValidation();

    const getServerTimeOnPageComeback = () => {
        if (document?.visibilityState === 'visible') {
            dispatch(getServerTime(timezone));
        }
    };

    useEffect(() => {
        if (userId) {
            sendToApptentive(APPTENTIVE_EVENTS.GAME_LAUNCHED, {
                product_account_id: userId,
                last_activity_date: userSessionStartTime
            });
        }
    }, [userId]);

    useEffect(() => {
        if (isValid && authToken) {
            dispatch(getCurrentSessionDetails());
            dispatch(getUserDetails());
            dispatch(getUserContext());
        }
    }, [authToken, isValid]);

    useEffect(() => {
        dispatch(getBalanceData(true));
    }, [isValid, authToken]);

    useEffect(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'Game Launch Initiated',
            [getAmplitudeKey('COMPONENT')]: 'log_game_launched',
            [getAmplitudeKey('MODULE')]: MODULES.GW,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.IN_GAME
        });

        //we need to set a common domain in order to be allowed to close a window
        // that was opened by another app (with same domain)
        // so we strip the original domain of 'launcher'
        try {
            if (document.domain) {
                document.domain = document.domain.replace('launcher.', '');
            }
        } catch (err) {
            console.error(err);
        }
        dispatch(getServerTime(timezone, () => setTickerInitialized(true)));
        document.addEventListener('visibilitychange', getServerTimeOnPageComeback);
        return () => {
            window?.performance?.clearMarks?.(SERVER_MARK);
            // window?.performance?.clearMarks?.(GAME_MARK);
            // TODO: find another fix for the issue with the game timer
            document.removeEventListener('visibilitychange', getServerTimeOnPageComeback);
        };
    }, [timezone]);

    useEffect(() => {
        const url = new URL(window.location.href);
        const jackpotModeParameter = url.searchParams.get('jackpotMode');

        const isSwjEnabled = swjToggle && (swjFeatureFlag || isPartOfSWJExperiment);

        if (isSwjEnabled && !jackpotModeParameter) {
            url.searchParams.set('jackpotMode', 'true');
            window.location.search = url.search;
        }
    }, [swjToggle, isPartOfSWJExperiment, swjFeatureFlag]);

    useEffect(() => {
        if (!isGameWindowDisabled()) {
            if (gameUid) {
                dispatch(fetchGameWindow(gameUid));
            } else {
                dispatch(fetchGameWindow());
            }
        }
    }, [dispatch]);

    useEffect(() => {
        const shouldPerformDirectHandshakeIOS = getQueryParameter('handshake');
        if (shouldPerformDirectHandshakeIOS) {
            dispatch(setGameClientHandshake(shouldPerformDirectHandshakeIOS === 'on'));
        }

        if (gameUid === undefined) {
            dispatch(
                setGameContext({
                    brand: getQueryParameter('brand'),
                    gameId: getQueryParameter('gameId'),
                    gameName: getQueryParameter('gameName'),
                    ganId: getQueryParameter('ganId'),
                    provider: getQueryParameter('providerName'),
                    providerRef: getQueryParameter('providerRef'),
                    region: getRegion(),
                    rgi,
                    overallStatus: rgi === RGIS.INCENTIVE_GAMES ? GAME_CONTEXT.OVERALL_STATUS.ACTIVE : overallStatus,
                    gameUid: getQueryParameter('gameUid')
                })
            );
            if (getQueryParameter('nativeId') === '-1') {
                dispatch(
                    setGameContext({
                        nativeId: getQueryParameter('nativeId'),
                        gameLoaded: true
                    })
                );
            }
        }
    }, [dispatch]);

    const renderByOverallStatus = status => {
        const shouldShowAllRegions = getCookieValue(COOKIES.X_SHOW_ALL_REGIONS_TOKEN);
        const shouldShowGame =
            shouldShowAllRegions ||
            status === GAME_CONTEXT.OVERALL_STATUS.ACTIVE ||
            status === GAME_CONTEXT.OVERALL_STATUS.ACTIVE_BUT_NOT_DISPLAYED ||
            status === GAME_CONTEXT.OVERALL_STATUS.ACTIVE_BUT_BETTING_DISABLED ||
            status === undefined;

        if (shouldShowGame) {
            return (
                <div className={`home ${isMobile ? 'mobile' : 'desktop'}`}>
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            flexDirection: 'row'
                        }}
                    >
                        <div style="flex-grow: 1">
                            <Loader message={lastRequest?.name} />
                            {isValid && authToken && gameProvider && (
                                <Beforeunload onBeforeunload={removeWrapperAppConfig}>
                                    <GameWindow gameProvider={gameProvider} />
                                </Beforeunload>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return <Maintenance exitGame={exitGame} />;
    };
    return (
        <Fragment>
            <WindowTitle />
            {renderByOverallStatus(overallStatus)}
        </Fragment>
    );
};

export default Home;
